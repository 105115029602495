main > footer {
  align-items: center;
  background: #d9d9d9;
  box-shadow: 0 -1px 3px rgba(64, 64, 64, 0.6);
  display: flex;
  padding: 1.5rem;
  position: relative;
}

.row-split {
  display: flex;
  gap: 2rem;
  & > * {
    flex: 1;
  }
}

.breadcrumbs ul {
  list-style: none;
  display: flex;

  padding: 0;
  margin: 0;
  align-items: center;
  font-size: 0.9rem;
  color: #6c757d;
}

.breadcrumbs ul li:after {
  content: ">";
  margin: 0 0.5rem;
  color: #adb5bd;
}

.breadcrumbs ul li:last-child {
  color: #495057;
}

.breadcrumbs ul li:last-child:after {
  content: "";
}

main > .breadcrumbs {
  margin-top: 0.5rem;
  margin-left: $general-size + 2;
}

main > header {
  align-items: center;
  border-bottom: 1px solid $stroke-color;
  display: flex;
  padding: 0.719rem 3rem;
  justify-content: space-between;
  position: relative;

  h1 {
    padding-bottom: 0 !important;
    font-weight: 700;
    font-size: 24px;
  }

  .buttons {
    display: flex;
    gap: 1rem;
  }
}

main > footer:not(.vertical) > :not(:last-child) {
  margin-right: 1.5rem;
}

body {
  body {
    height: 100vh;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100vw;
  }
}

main {
  flex: 1;
  z-index: 0;
  width: 100%;
}

@media only screen and (min-width: 961px) {
  main > .content {
    padding: 3rem;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

app-root > .content__wrapper {
  display: flex;
  flex: 1;
  max-height: 100vh;
}

.content {
  background-color: $main-background-color;
  flex: 1;
  overflow: auto;
  padding: $general-size * 3;

  &::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #efefef; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $main-button-color; /* color of the scroll thumb */
    border-radius: $border-radius; /* roundness of the scroll thumb */
  }
}

main {
  flex: 1;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

app-root router-outlet + * {
  display: flex;
  flex: 1;
  min-height: 0;
  width: 0;
}

@media only screen and (min-width: 961px) {
  main > .content {
    padding: 3rem;
  }
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .flow {
    margin-top: 0;
  }
}

.flow {
  display: flex;
  flex-direction: column;

  & + .flow {
    margin-top: $general-size;
  }

  &.align-end {
    width: fit-content;
    .form-box-row {
      align-self: flex-end;
    }
  }

  .flow {
    margin-top: 0;
  }
}

// Margins
.m__0 {
  margin: 0 !important;
}

.mb__0 {
  margin-bottom: 0 !important; // Adds space between heading and line
}

.mb__1 {
  margin-bottom: $general-size !important; // Adds space between heading and line
}

.mb__2 {
  margin-bottom: $general-size * 2 !important; // Adds space between heading and line
}

.mb__3 {
  margin-bottom: $general-size * 3 !important; // Adds space between heading and line
}

.mt__0 {
  margin-top: 0 !important; // Adds space between heading and line
}

.mt__1 {
  margin-top: $general-size !important; // Adds space between heading and line
}

.mt__2 {
  margin-top: $general-size * 2 !important; // Adds space between heading and line
}

.mt__3 {
  margin-top: $general-size * 3 !important; // Adds space between heading and line
}
