p-breadcrumb {
  .p-breadcrumb {
    background: $main-background-color !important;
    padding: var(--p-breadcrumb-padding) 2.5rem !important;
  }
  &.x-padding-0 {
    .p-breadcrumb {
      padding: var(--p-breadcrumb-padding) 0 !important;
    }
  }
}

.p-breadcrumb-item-link,
.p-breadcrumb-current-item-link {
  font-size: 14px;
}

.p-tabpanels,
.p-tablist-tab-list {
  background: $main-background-color !important;
}

.p-tab-active {
  border-color: $secondary-color !important;
  color: $dark-font-color !important;
}

.p-tab {
  font-weight: 500 !important;
  color: $label-color;
}
////////////////
.grid {
  gap: $general-size;
  &__gap__2 {
    gap: $general-size * 2 !important;
  }
}

.p-drawer {
  border: none !important;

  .p-drawer-content,
  .p-drawer-footer {
    background-color: $main-background-color;
  }

  .p-drawer-header {
    background-color: #2d514e;
    color: $light-font-color;
    font-size: 18px;
    padding: 0.5rem 1.25rem !important;
    margin-bottom: 1.25rem;
    .closeButton {
      .p-button {
        color: $light-font-color !important;
      }
    }
  }
}

.p-card {
  border-radius: $border-radius !important;
  .p-card-header {
    background-color: #2d514e;
    color: $light-font-color;
    padding: 0.75rem 1.25rem !important;
    border-radius: $border-radius $border-radius 0 0;
  }
}

.p-timeline-event-opposite {
  max-width: 140px !important;
  color: #777777;
  font-weight: 500;
}

.p-timeline-event-content {
  color: #777777;
  .user__name {
    font-weight: 700;
    color: $dark-font-color;
  }
}

.p-autocomplete-dropdown {
  border-radius: inherit;
  border-start-end-radius: var(--p-autocomplete-dropdown-border-radius);
  border-end-end-radius: var(--p-autocomplete-dropdown-border-radius);
  border: 1px solid var(--p-autocomplete-dropdown-border-color);
  border-inline-start: 0 none;
}

.p-togglebutton-label,
.p-togglebutton-icon {
  position: relative;
  transition: none;
  text-transform: initial;
}
