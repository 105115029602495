@use 'card-software-library';
@import "primeicons/primeicons.css";
/* Remove the spinner in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Remove spin buttons in WebKit browsers (Chrome, Safari, Edge Chromium) */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*
RECOMMENDATION
NOTE: Add this code into library (CSL) for update and fix the styles on lists because the code into library generate issues by incompatible styles with tailwindcss
*/

//assterisk for a label required
label.required::after {
  content: '*';
  color: red;
}

ul.cs-pattern {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  li {
    width: 100% !important ;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-bottom: 1px solid #d1d5db !important;
    padding: 12px 20px;

    &:nth-child(odd) {
      background-color: #eef3f3;
    }

    .actions {
      display: flex !important;
      align-items: center !important;
      gap: 1.25rem !important;
    }
  }
}

/*END RECOMMENDATION*/
