section > header.line::after,
section > h1.line::after,
section > h2.line::after,
section > h3.line::after,
section > h4.line::after,
section > h5.line::after,
section > h6.line::after {
  content: "";
  display: block; // Ensures it appears on a new line
  border-top: 1px solid $stroke-color;
  width: 100%; // Spans full heading width
  margin-top: 0.375rem; // Adds space between heading and line
  margin-bottom: $general-size * 3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column; // Stack heading and pseudo-element vertically
  align-items: flex-start; // Align text and line properly
  color: $dark-font-color;
  letter-spacing: 0.5px;
  font-weight: 700;
}

header,
h1,
h2,
h3,
h4,
h5,
h6 {
  &.line {
    &::after {
      content: "";
      display: block; // Ensures it appears on a new line
      border-top: 1px solid $stroke-color;
      width: 100%; // Spans full heading width
      margin-bottom: $general-size * 3; // Adds space between heading and line
    }
    &__0 {
      &::after {
        margin-bottom: 0 !important; // Adds space between heading and line
      }
    }
    &__1 {
      &::after {
        margin-bottom: $general-size !important; // Adds space between heading and line
      }
    }
    &__2 {
      &::after {
        margin-bottom: $general-size * 2 !important; // Adds space between heading and line
      }
    }
  }
}

header.line > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.line {
  &.float__button {
    .button__wrapper {
      position: absolute;
      display: flex;
      right: 0;
      gap: 0.5rem;
    }
  }
  &::after {
    margin-top: 1.25rem !important;
  }
}

header.line,
h1.line,
h2.line,
h3.line,
h4.line,
h5.line,
h6.line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

// Remove top padding if Toolbar follows h1, h2, h3, etc.
h1 + [aria-label="Toolbar"],
h2 + [aria-label="Toolbar"],
h3 + [aria-label="Toolbar"],
h4 + [aria-label="Toolbar"],
h5 + [aria-label="Toolbar"],
h6 + [aria-label="Toolbar"] {
  padding-top: 0.5rem !important;
}

[aria-label="Toolbar"] {
  display: flex;
  align-items: center;
  justify-content: flex-start; // Default: Align buttons to the left
  padding: 1rem;
  background: none;
  border: none;
}

// Class to align buttons to the right
.toolbar-right {
  justify-content: flex-end; // Aligns buttons to the right
}

// Class to align buttons to the left (redundant but explicit)
.toolbar-left {
  justify-content: flex-start; // Aligns buttons to the left
}

// Remove any unnecessary top border from toolbars following specific elements
h1 + [aria-label="Toolbar"],
h2 + [aria-label="Toolbar"],
h3 + [aria-label="Toolbar"],
h4 + [aria-label="Toolbar"],
h5 + [aria-label="Toolbar"],
h6 + [aria-label="Toolbar"],
ol.collection:not(.cards) + [aria-label="Toolbar"],
table:not(.simple) + [aria-label="Toolbar"],
ul.collection:not(.cards) + [aria-label="Toolbar"] {
  border-top: none;
}

.subtitle {
  color: #555555;
  margin-top: 1rem;
  &.line::after {
    content: "";
    display: block; // Ensures it appears on a new line
    border-top: 1px solid $stroke-color;
    width: 100%; // Spans full heading width
    margin-bottom: $general-size; // Adds space between heading and line
  }
  &__margin-0 {
    margin-top: 0;
  }
}
