ul.cs-pattern {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  li {
    width: 100% !important ;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-bottom: 1px solid #d1d5db !important;
    padding: 12px 20px;

    &:nth-child(odd) {
      background-color: #eef3f3;
    }

    .actions {
      display: flex !important;
      align-items: center !important;
      gap: 1.25rem !important;
    }
  }
}
