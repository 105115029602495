.panel > * > .content,
main > .content {
  background: $main-background-color;
  flex: 1;
  overflow: auto;
  padding: 1.5rem;
}

.panel > * > header > *,
.row > * {
  flex: 0 1 auto;
}

.panel > * > header {
  padding: 0rem 0.75rem;
}

main > footer:not(.vertical) > *:not(:last-child),
.panel > * > footer:not(.vertical) > *:not(:last-child) {
  margin-right: 1.5rem;
}

.panel > * > header > *:first-child {
  flex: 1 0 auto;
  margin-right: 2.25rem;
}

.panel.modal {
  // Target the first child of .panel.modal
  > :first-child {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
}

.panel.modal > * > header {
  background: $secondary-color !important;
  color: $light-font-color;
  position: relative;
  align-items: center;

  h2 {
    margin-top: 0rem;
    padding: 1rem 0.5rem;
    color: white;
    font-size: 18px;
  }
}

.panel > * > footer,
main > footer {
  align-items: center;
  border-top: 1px solid $stroke-color;
  background: $main-background-color !important;
  display: flex;
  padding: 1.5rem;
  position: relative;
  button {
    width: 100%;
  }
  &.main__actions {
    box-shadow: none;
    justify-content: flex-end;
    padding-left: 3rem;
    padding-right: 3rem;
    button {
      width: 150px !important;
    }
  }
}

.panel {
  &.left {
    // TODO: what is this?
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); // vars.shadow('rightward');
    max-width: 33vw;
    z-index: 80;
  }
  &.fiber-sidebar {
    // @include _side-panel-internal-styling($theme);
  }
  &.modal {
    background-color: $main-light-color;
    // body.dark & {
    //   background-color: #333; // vars.color('grayscale', '20');
    // }
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1); // vars.shadow('leftward');
    max-width: 400px;
    width: 100%;
    @media only screen and (max-width: 800px) {
      // vars.breakpoint('narrow')
      max-width: calc(100vw - 3rem); // vars.sizing('2')
    }
    position: fixed;
    top: 0; // vars.sizing('panels', 'header')
    right: 0;
    bottom: 0;
    z-index: 150;
    height: 100vh;
    &:not(.confirm) {
      @keyframes fiber-modal-slide-in {
        from {
          transform: translate3d(100%, 0, 0);
        }
        to {
          transform: translate3d(0, 0, 0);
        }
      }
      @keyframes fiber-modal-slide-out {
        from {
          transform: translate3d(0, 0, 0);
        }
        to {
          transform: translate3d(100%, 0, 0);
        }
      }
      animation: fiber-modal-slide-in 0.2s both linear;
      &.destroy {
        animation: fiber-modal-slide-out 0.2s both linear;
      }
      // @include _side-panel-internal-styling($theme);
    }
    &.confirm {
      border-top: 1px solid #d3d3d3; // vars.color('grayscale', '15');
      border-bottom: 1px solid #d3d3d3; // vars.color('grayscale', '15');
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2); // vars.shadow('downward');
      flex-direction: row;
      height: auto;
      width: 100%;
      max-width: 100%;
      bottom: inherit;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      align-items: center;
      @keyframes fiber-confirm-modal-slide-in {
        from {
          opacity: 0;
          top: 25vh;
        }
        to {
          opacity: 1;
          top: 40vh;
        }
      }
      @keyframes fiber-confirm-modal-slide-out {
        from {
          opacity: 1;
          top: 40vh;
        }
        to {
          opacity: 0;
          top: 25vh;
        }
      }
      animation: fiber-confirm-modal-slide-in 0.2s both ease-in-out;
      &.destroy {
        animation: fiber-confirm-modal-slide-out 0.2s both ease-in-out;
      }
    }
  }
}

.veil {
  position: absolute;
  top: 0; // vars.sizing('panels', 'header')
  height: 100vh; // vars.sizing('panels', 'header')
  left: 0;
  width: 100vw;
  z-index: 140;
  background-color: #000000;
  @keyframes fiber-modal-veil-in {
    from {
      visibility: hidden;
      opacity: 0;
    }
    to {
      visibility: visible;
      opacity: 0.5;
    }
  }
  @keyframes fiber-modal-veil-out {
    from {
      visibility: visible;
      opacity: 0.5;
    }
    to {
      visibility: hidden;
      opacity: 0;
    }
  }
  animation: fiber-modal-veil-in 0.5s both ease-in-out;
  &.destroy {
    animation: fiber-modal-veil-out 0.5s both ease-in-out;
  }
}

.actions {
  display: flex !important;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}
