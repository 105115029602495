// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($asset-ui-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($asset-ui-theme);
@use "@angular/material" as mat;

@import "./core/variables.scss";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./core/tables";
@import "./core/panels";
@import "./core/button";
@import "./core/layout";
@import "./core/input";
@import "./core/headers";
@import "./core/lists";
@import "./core/flex-grid";
@import "./core/cards";
@import "./core/primeng";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the theme object.
$item-ui-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($item-ui-theme);
}

html,
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Stuff that should go in npm package

// #region Layout

app-nav-menu {
  position: relative;
  max-width: 240px;
  width: 100%;
  background: $secondary-color;
  box-shadow: 0px 4px 50px #d9ddfc;
  overflow: auto;
  z-index: 110;
}

// #endregion

// #region inputs

// #endregion

section {
  padding-bottom: calc(2 * 1.5rem);
}

.panel > * > header > :not(:last-child),
.row > :not(:last-child) {
  margin-right: 1.5rem;
}

// #region form

.table-form {
  width: auto;
  text-align: center;
}

.align,
form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}

p.error {
  color: $error-color;
}

.form-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: $general-size;
  &:last-child {
    margin-bottom: 0;
  }
}

.form-box-row {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: $general-size;
  &:last-child {
    margin-bottom: 0;
  }
  label {
    max-width: 140px;
    width: 100%;
  }
}

label {
  color: $label-color;
  font-size: $general-size;
  line-height: 1.5rem;
  font-weight: 500;
}

// #endregion

.flow > .col > * + *:not(.row),
.align > * + *:not(.row),
form > * + *:not(.row) {
  margin-top: $general-size;
}

.draggable {
  cursor: move;
}

// #endregion

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// #endregion

// #region toggles
.toggle-box {
  max-width: 100%;
  width: fit-content;

  &.switch {
    position: relative;

    input[type="checkbox"] {
      width: 2.25rem;
      height: 1.5rem;
      opacity: 0;
      position: absolute;
      z-index: 1;
      cursor: pointer;
    }

    label {
      padding-left: 2.55rem;
      display: inline-flex;
      align-items: center;
      position: relative;
      color: $label-color;
      font-size: 1rem;
      line-height: 1.5rem;
      cursor: pointer;

      &:before {
        content: "";
        display: inline-block;
        width: 2.25rem;
        height: 1.5rem;
        background: #b3b3b3;
        border-radius: 1.5rem;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: background 0.2s ease;
      }

      &:after {
        content: "";
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background: $main-light-color;
        border: 1px solid #262626;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition: transform 0.2s ease;
        box-shadow: 0 1px 1px rgba(64, 64, 64, 0.6);
      }
    }

    input[type="checkbox"]:checked + label {
      &:before {
        background: #3f0080;
      }

      &:after {
        transform: translate(50%, -50%);
      }
    }
  }
}

// #region Tabs

p.informative {
  color: $label-color;
  font-size: 1rem;
  line-height: 1.5rem;
}

p.question {
  color: red;
}

app-header {
  width: 100%;
  z-index: 180;
  height: 4.5rem;
  position: relative;
}

/* Remove the spinner in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove spin buttons in WebKit browsers (Chrome, Safari, Edge Chromium) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
