table.standard {
  border-collapse: collapse;
  border-radius: 5px 5px 0 0; // Apply radius to top-left and top-right corners
  overflow: hidden; // Ensures border-radius is applied properly
  width: 100%;
  table:not(.selectable) tr > :first-child {
    padding-left: 0;
  }

  thead {
    background-color: $primary-color;
  }

  thead th {
    border-top: 1px solid #ccc;
  }

  th {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
  }

  td,
  th {
    background: transparent;
    border-bottom: 1px solid #ccc;
    padding: 1.125rem 0.75rem;
    text-align: left;
    vertical-align: middle;
  }
}

table.pattern {
  width: 100%;
  border-collapse: collapse;

  tbody {
    tr:nth-child(odd) {
      background-color: $main-light-color;
    }

    tr:nth-child(even) {
      background-color: #eef3f3;
    }

    td {
      padding: 0.75rem;
      text-align: left;
      font-size: 0.9rem;
    }
  }
}

.body.filtered-list {
  display: flex;
  flex-direction: row;
  margin-top: 0 !important;
}

.body.filtered-list > .filters {
  form {
    width: 100%;
    input {
      width: 100%;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    button:nth-child(2) {
      margin-left: 0.5rem;
    }
  }
  height: fit-content;
  background: #f2f2f2;
  padding: 1.5rem;
  box-shadow: 1px 0 3px #40404099;
  margin-right: 2.25rem;
}

.body.filtered-list > .list {
  flex: 1;
}

/// *** PrimeNG TABLE Styles *** ///

.p-datatable-tbody > tr {
  background: transparent !important;
  &.tr__clickable {
    cursor: pointer;
    &:hover {
      background: $main-light-color !important;
    }
  }
}

.sm__table {
  .p-datatable {
    .p-datatable-table-container {
      .p-datatable-table {
        font-size: 12px;
        font-weight: 500;
        color: $dark-font-color;
      }
    }
  }
}

.p-datatable-thead {
  > tr {
    &.dark__header {
      background: $main-dark-color;
      color: $light-font-color;
    }
    &.primary__header {
      background: $primary-color;
      color: $dark-font-color;
      font-weight: 500;
    }
    > td,
    th {
      text-align: start;
      border-color: var(--p-datatable-body-cell-border-color);
      border-style: solid;
      border-width: 0 0 1px 0;
      padding: var(--p-datatable-body-cell-padding);
      background: transparent;
      color: $light-font-color;
      &:first-child {
        border-radius: $border-radius 0 0 $border-radius;
      }
      &:last-child {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }
  }
}

.p-datatable-tfoot > tr > td {
  background: #a8dadc1a !important;
}

.p-datatable-table-container {
  &::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #efefef; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: $main-button-color; /* color of the scroll thumb */
    border-radius: $border-radius; /* roundness of the scroll thumb */
  }
}

.table__actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
