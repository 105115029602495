$col_width: 100%/12;

.flow__cols__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $general-size;
  
  .col {
    width: 100%;
    @media (width > 768px) {
      &__auto {
        flex: 1;
      }

      &__12 {
        width: calc(($col_width * 12) - (#{1 - (1/2)}rem));
      }

      &__11 {
        width: calc(($col_width * 11) - (#{1 - (1/2)}rem));
      }

      &__10 {
        width: calc(($col_width * 10) - (#{1 - (1/2)}rem));
      }

      &__9 {
        width: calc(($col_width * 9) - (#{1 - (1/2)}rem));
      }

      &__8 {
        width: calc(($col_width * 8) - (#{1 - (1/2)}rem));
      }

      &__7 {
        width: calc(($col_width * 7) - (#{1 - (1/2)}rem));
      }

      &__6 {
        width: calc(($col_width * 6) - (#{1 - (1/2)}rem));
      }

      &__4 {
        width: calc(($col_width * 4) - (#{1 - (1/3)}rem));
      }
    
      &__3 {
        width: calc(($col_width * 3) - (#{1 - (1/4)}rem));
      }
    
      &__2 {
        width: calc(($col_width * 2) - (#{1 - (1/5)}rem));
      }
    }
  }
}

@media (max-width: 768px) {
  .flow__cols__row {
    flex-direction: column;
  }
}