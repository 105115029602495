// Define color variables
$primary-color: #5aa29d; // Sierra Textiles' primary color
$secondary-color: #2d514e; // Secondary color
$main-background-color: #fafbff; // Content background color
$main-dark-color: #333333;
$main-light-color: #ffffff;
// Buttons
$main-button-color: #2d514e; // Main button color
$cancel-button-color: #e63946; // Cancel button color
$normal-button-color: #a8dadc; // Normal button color
$error-color: #e63946; // Error color

$hover-primary-button: lighten(#2d514e1a, 10%);
$hover-main-button: #5aa29d;
$hover-main-white-button: lighten($main-button-color, 50%);
$hover-cancel-button: darken($cancel-button-color, 10%);
$hover-normal-button: lighten($normal-button-color, 10%);
$hover-primary-color: darken($primary-color, 10%);
$hover-secondary-color: lighten($secondary-color, 10%);

$border-radius: 8px;
$stroke-color: #e2e8f0;
$general-size: 1rem;

// Fonts
$label-color: #0000008a;
$dark-font-color: #333333;
$light-font-color: #ffffff;
