//button flat

button.flat,
button.square {
  box-shadow: none;
  height: 2.25rem;
}

button.flat > .material-icons {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-right: 0.375rem;
  vertical-align: top;
}

button.flat {
  display: flex;
  align-items: center;
  &.w-full {
    justify-content: center;
  }
}

//button

button > .material-icons {
  font-size: 1.25rem;
  line-height: 1.5rem;
  vertical-align: top;
}

button {
  background-color: $main-light-color;
  color: $main-button-color;
  font-size: 0.875rem;
  font-weight: 500;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-transform: uppercase;
  transition: all 0.12s ease;
  white-space: nowrap;
  border-radius: $border-radius;
  border: 1px solid $main-button-color;
}

//button mainAction

button.mainAction:not(:disabled):hover {
  background: $hover-main-button;
}

button:not(:disabled):hover {
  background: $hover-main-white-button;
  outline: none;
}

button:not(:disabled).mainAction {
  background-color: $main-button-color;
  color: $light-font-color;
}

button.mainAction:disabled,
button:disabled {
  box-shadow: 0 0 0 1px #ccc;
  color: rgba(0, 0, 0, 0.38);
  cursor: not-allowed;
}

button.flat:not(:disabled):hover:not(.red):not(.green),
button.flat:not(:disabled):focus:not(.red):not(.green),
button.square:not(:disabled):hover:not(.red):not(.green),
button.square:not(:disabled):focus:not(.red):not(.green),
button:not(:disabled):hover:not(.red):not(.green),
button:not(:disabled):focus:not(.red):not(.green) {
  background-color: #2d514e1a;
}

button.mainAction:not(:disabled):hover:not(.red):not(.green),
button.mainAction:not(:disabled):focus:not(.red):not(.green) {
  background-color: #5aa29d;
}

button.square {
  padding-left: 0;
  padding-right: 0;
  width: 2.25rem;
}

button.flat,
button.square {
  height: 2.25rem;
}

a {
  color: $main-button-color;
  text-decoration: underline;
  &:hover {
    color: $hover-main-button;
    cursor: pointer;
  }
}

button.border-none {
  border: none;
  background: none;
  max-width: fit-content;
  padding: 5px 8px;
}
