.primary__card {
  background-color: #f9fcfc;
  border: 1px solid #dcecec;
  border-radius: $border-radius;
  padding: $general-size;
}

%card-common {
  max-width: fit-content;
  border-radius: $border-radius;
  padding: 1rem;
  border: 1px solid $stroke-color !important;
}

.light-blue-card {
  @extend %card-common;
  background: #a8dadc1a;
}

.transparent-card {
  @extend %card-common;
  background: #f1faee1a;
}

.production__card {
  display: block;
  margin-top: 1rem;
  &__active {
    .p-panel {
      background-color: #a8dadc1a;
    }
  }
  .p-panel {
    .p-panel-header {
      padding-top: 1rem;
      padding-bottom: 1rem;
      h4 {
        font-weight: 700;
      }
      .checkbox__wrapper {
        float: right;
        margin-left: 1rem;
      }
      .status__badge {
        float: right;
      }
    }

    .p-panel-icons {
      display: flex;
      align-items: center;
      .panel__actions {
        display: flex;
        gap: 1rem;
        margin-right: 1rem;
      }
    }

    .p-panel-content {
      margin-top: 1rem;
    }

    .p-panel-footer {
      padding-bottom: 1rem;
      .date__info {
        text-align: right;
        color: #777777;
        font-weight: 500;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .divider {
          display: inline-block;
          background-color: #2d514e;
          width: 6px;
          height: 6px;
          margin: 0 0.5rem;
          border-radius: 16px;
        }
      }
    }
  }
}

.quantity-card {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 8px;
  background: #5aa29d;
  margin-top: 1rem;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  .divider {
    border-left: 1px solid;
    position: absolute;
    left: 50%;
    height: calc(100% - 1rem);
  }
  h2 {
    font-weight: 700;
    align-items: center;
    color: inherit;
  }
  h4 {
    font-weight: 500;
    align-items: center;
    color: inherit;
    text-align: center;
  }
}
