label.required::after {
  content: "*";
  color: red;
}

/// *** PrimeNG INPUT Styles *** ///

.p-datepicker,
.p-password,
.p-password-input {
  width: 100%;
}

.p-floatlabel {
  width: 100%;
  input {
    width: 100%;
  }
}

.float__icon__input {
  position: relative;
  .pi {
    position: absolute;
    margin: auto;
    right: -24px;
    cursor: pointer;
  }
}

.drop__wrapper {
  padding: 2rem;
  border: 2px solid #dedede;
  border-radius: 8px;
  border-style: dashed;
  text-align: center;
  label {
    color: #5aa29d !important;
  }
}

.p-autocomplete {
  width: 100%;
  input {
    width: 100%;
  }
}

.header__input {
  .p-autocomplete-option {
    font-size: initial !important;
    line-height: initial !important;
    font-weight: normal !important;
  }
}

// input:not([type="checkbox"]):not([type="radio"]):not([disabled]):focus,
// input:not([type="checkbox"]):not([type="radio"]):not([disabled]):hover,
// select:not([disabled]):focus,
// select:not([disabled]):hover,
// textarea:not([disabled]):focus,
// textarea:not([disabled]):hover {
//   background-color: $hover-primary-button;
//   outline: none;
// }

// input:not([type="checkbox"]):not([type="radio"]):not([disabled]),
// textarea:not([disabled]) {
//   border-radius: $border-radius;
//   border: 1px solid $stroke-color;
//   &:focus {
//     border: 1px solid $stroke-color;
//   }
// }

// input:not([type="checkbox"]), select {
//   height: 2.25rem;
//   line-height: 2.25rem;
//   &.size__sm {
//     max-width: 100px;
//   }
//   &.size__md {
//     max-width: 200px;
//   }
// }

// input {
//   &.search {
//     background: url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&icon_names=search")
//       no-repeat 90% 10%;
//   }
// }

// .input-wrapper {
//   position: relative;
//   display: flex;
//   align-items: center;

//   &::before {
//     content: "search"; // Replace with any Material Icon name
//     font-family: "Material Icons";
//     font-size: 18px;
//     position: absolute;
//     left: 10px;
//     color: #777;
//   }

//   input {
//     padding-left: 40px;
//     width: 85%;
//     height: 2.1rem;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//   }
// }

// input,
// select,
// textarea {
//   font-family: Roboto, sans-serif;
//   font-size: 1rem;
//   padding: 0.25rem 0.5rem;
//   width: 100%;
// }

// input:disabled {
//   border-color: rgba(118, 118, 118, 0.3) !important;
// }

// select {
//   border-radius: $border-radius;
//   border: 1px solid $stroke-color;
//   padding: 3px 8px;
//   cursor: pointer;
//   background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
//   background-position: right 8px top 12px;
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   appearance: none;
//   line-height: normal;
//   background-color: white;
// }

// .input-checkbox {
//   display: flex;
//   align-items: center;
//   flex-direction: row-reverse;
//   gap: 8px;
//   border-radius: $border-radius;
//   transition: all 0.2s ease-in-out;
//   cursor: pointer;
//   padding: 3px 8px;
//   border: 1px solid $stroke-color;

//   &__label {
//     font-size: 14px;
//     cursor: pointer;
//   }

//   input[type="checkbox"] {
//     appearance: none;
//     width: 18px;
//     height: 18px;
//     border: 1px solid $stroke-color;
//     color: #fff;
//     border-radius: 5px;
//     cursor: pointer;
//     position: relative;
//     &:checked {
//       background: $secondary-color;
//       border-color: $secondary-color;
//       &::after {
//         content: "\2713";
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         font-size: 14px;
//         color: #fff; // Cambia el color del texto a un color más oscuro
//       }
//     }
//   }
// }

// input:disabled,
// select:disabled,
// textarea:disabled {
//   cursor: not-allowed;
//   pointer-events: none;
//   background: $stroke-color;
//   border-radius: $border-radius;
// }

// input:not([type="checkbox"]):read-only,
// textarea:read-only {
//   border: none;
//   background: $stroke-color;
//   cursor: not-allowed;
//   pointer-events: none;
//   &:hover {
//     background: $stroke-color !important;
//   }
// }
